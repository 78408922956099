import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div`
  min-width: 100%;
  min-height: 300px;
  max-height: 300px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: top;

  ${props => {
    if (props.dimmed) {
      return `background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(${
        props.img
      }) no-repeat !important;`
    } else {
      return `background-image: url(${props.img})`
    }
  }}
`

const Text = styled.h1`
  color: white;
  padding-left: 3em;
  font-size: 2.5em;
`

class Hero extends Component {
  render() {
    return (
      <Container img={this.props.img} dimmed={this.props.dimmed}>
        <Text>{this.props.title}</Text>
      </Container>
    )
  }
}

export default Hero
